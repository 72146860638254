import React from "react"
import TemplatePageEbook  from "@components/pageEbook/template"
import { Helmet } from "react-helmet"
import data from "@components/pageEbook/ebooks/ebook7/dataPageEbook7"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>
        {" "}
        Guía del 2022 para elegír el mejor software de call center
       [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/ebook/mejor-software-call-center/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content=" ¿Quieres saber cómo elegir el mejor software de call center? Presentamos una guía completa para que elgas el más adecuado para ti.  "
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Guía del 2022 para elegír el mejor software de call center"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/ebook/mejor-software-call-center/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/Ebook/og-imgEbook7.jpg`}
      />
      <meta
        property="og:description"
        content=" ¿Quieres saber cómo elegir el mejor software de call center? Presentamos una guía completa para que elgas el más adecuado para ti."
      />
    </Helmet>
    <TemplatePageEbook ebook="Contact Center" location={location} typeEbook="ebook7" clickHereHref="https://beexcc.com/blog/noticias-contact-center/" data={data} />
  </div>
)

export default IndexPage
